import enums from './enums.json';

export const Enums = enums.enums;

export const APP_ENV       = process.env.REACT_APP_APP_ENV;
export const CAN_USE_SLOCK = process.env.REACT_APP_CAN_USE_SLOCK;
export const APP_NAME      = process.env.REACT_APP_NAME;
export const BASE_URL      = process.env.REACT_APP_BASE_URL;
export const API_URL       = process.env.REACT_APP_API_URL;
export const WEB_URL       = process.env.REACT_APP_WEB_URL;
export const STORAGE_URL   = process.env.REACT_APP_STORAGE_URL;
export const PAYJP_KEY     = process.env.REACT_APP_PAYJP_PUBLIC_KEY;
export const ANALYTICS_ID  = process.env.REACT_APP_ANALYTICS_ID;

export const GetEnumsName = (model: any, id: number) => {
  let name = '';
  Object.keys(model).forEach((key) => {
    if (model[key]['id'] === id) {
      name = model[key]['ja'];
      return false;
    }
  });

  return name;
}
