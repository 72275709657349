import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useTitle } from 'react-use';
import { Container, Breadcrumb, Card, CardDeck, Nav, Row, Col, Table, Button, ListGroup, ListGroupItem } from 'react-bootstrap'

import { Header } from 'views/_header';
import { Footer } from 'views/_footer';

export const Index = () => {
  useTitle('店舗一覧 | 激安・便利なレンタルバイクのヤスカリ。');

  return (
    <>
    <Header />
    <Container fluid="lg">
      <Breadcrumb className="mt-3">
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>ホーム</Breadcrumb.Item>
        <Breadcrumb.Item active>店舗一覧</Breadcrumb.Item>
      </Breadcrumb>

      <h1 className="border-l-4	border-red-700 text-2xl text-red-700 font-bold pl-2">店舗一覧</h1>

      <Card className="mt-3" id="shop1">
        <Card.Header>足立小台本店</Card.Header>
        <Card.Body>
          <Card.Title>足立区にある格安バイク屋です。</Card.Title>
          <Card.Text>
            <div className="google-map-code">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3237.783976744857!2d139.757146650922!3d35.75611403337134!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188df624d4cbb1%3A0xcb05fe495b4f4746!2z44CSMTIwLTAwNDYg5p2x5Lqs6YO96Laz56uL5Yy65bCP5Y-w77yS5LiB55uu77yZ4oiS77yX!5e0!3m2!1sja!2sjp!4v1645341798689!5m2!1sja!2sjp" width="100%" height="450" frameBorder="0" style={{border:0}} aria-hidden="false"></iframe>
            </div>
          </Card.Text>
        </Card.Body>
        <ListGroup className="list-group-flush">
          <ListGroupItem>舎人ライナー『足立小台』駅から徒歩15分   </ListGroupItem>
          <ListGroupItem>都電荒川線(東京さくらトラム)『小台』駅から徒歩15分</ListGroupItem>
          <ListGroupItem>JR田端駅から・都バス【東43】荒川土手行き・江北駅前行き・豊島五丁目団地行き乗車・小台二丁目下車</ListGroupItem>
        </ListGroup>
        <Card.Body>
          <Table responsive className="text-nowrap">
            <tbody>
              <tr>
                <th className="w-25">屋号</th>
                <td>ヤスカリ</td>
              </tr>
              <tr>
                <th>運営会社</th>
                <td>株式会社ケイジェット</td>
              </tr>
              <tr>
                <th>郵便番号</th>
                <td>〒120-0046</td>
              </tr>
              <tr>
                <th>所在地</th>
                <td>東京都足立区小台2-9-7 1階</td>
              </tr>
              <tr>
                <th>メール</th>
                <td>info[at]yasukari.com</td>
              </tr>
              <tr>
                <th>営業時間</th>
                <td>10:00 〜 19:00 （月曜定休）</td>
              </tr>
            </tbody>
          </Table>
          <Button variant="success" block as={Link} to="/models/shops/1">この店舗でレンタル予約</Button>
        </Card.Body>
      </Card>

      <Card className="mt-5" id="shop2">
        <Card.Header>三ノ輪店</Card.Header>
        <Card.Body>
          <Card.Title>東京都台東区の国道4号線沿いにあるレンタルバイク店です。</Card.Title>
          <Card.Text>
            <div className="google-map-code">
              <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6478.075023885486!2d139.7839183478545!3d35.72529596156836!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188e8e9bd6c8af%3A0x9760330aa4407d9b!2z44CSMTEwLTAwMDQg5p2x5Lqs6YO95Y-w5p2x5Yy65LiL6LC377yT5LiB55uu77yR77yW4oiS77yR77yU!5e0!3m2!1sja!2sjp!4v1579678899781!5m2!1sja!2sjp" width="100%" height="450" frameBorder="0" style={{border:0}} aria-hidden="false"></iframe>
            </div>
          </Card.Text>
        </Card.Body>
        <ListGroup className="list-group-flush">
          <ListGroupItem>東京メトロ日比谷線 三ノ輪駅 徒歩4分</ListGroupItem>
          <ListGroupItem>東京メトロ日比谷線 入谷駅 徒歩7分</ListGroupItem>
        </ListGroup>
        <Card.Body>
          <Table responsive className="text-nowrap">
            <tbody>
              <tr>
                <th className="w-25">店名</th>
                <td>ヤスカリ 三ノ輪店</td>
              </tr>
              <tr>
                <th>郵便番号</th>
                <td>〒110-0004</td>
              </tr>
              <tr>
                <th>所在地</th>
                <td>東京都台東区下谷3ー16ー14</td>
              </tr>
              <tr>
                <th>メール</th>
                <td>info[at]yasukari.com</td>
              </tr>
              <tr>
                <th>営業時間</th>
                <td>10:00 〜 19:00 （月曜定休）</td>
              </tr>
              {/*
              <tr>
                <th>営業時間</th>
                <td>24時間365日 無人対応</td>
              </tr>
              <tr>
                <th>備考</th>
                <td>予約詳細ページから鍵の発行が可能です</td>
              </tr>
              */}
            </tbody>
          </Table>
          <Button variant="success" block as={Link} to="/models/shops/2">この店舗でレンタル予約</Button>
        </Card.Body>
      </Card>

    </Container>

    <Footer />
    </>
  );
};

export default Index;
