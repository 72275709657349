import React from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { useTitle } from 'react-use';
import { Container, Navbar, Nav, ListGroup, Card, Button, Row, Col, Breadcrumb, Table, Modal } from 'react-bootstrap'
import NumericLabel from 'react-pretty-numbers';
import {confirm} from 'react-bootstrap-confirmation';
import { NotificationManager } from 'react-notifications';
import { QRCodeCanvas } from "qrcode.react";

import moment from 'moment';
import 'moment/locale/ja';

import { Header } from 'views/_header';
import { Footer } from 'views/_footer';
import { LogoutButton } from 'views/my/_logout_button';
import { getFetch, getFetchBlob, postFetch } from 'api/fetch';
import { MyReservationsShow as Response } from 'api/my';
import { API_URL, GetEnumsName, Enums, CAN_USE_SLOCK } from 'config/const';
import{ LockPin } from 'models/lock_pin';

export const Show = () => {
  useTitle('予約詳細 | マイページ | 激安・便利なレンタルバイクのヤスカリ。');

  const { id } = useParams<Record<string, string | undefined>>()
  const [response, setResponse] = React.useState<Response | undefined>(undefined);
  const [lockPin, setLockPin] = React.useState<LockPin | undefined>(undefined);
  const [show, setShow] = React.useState(false);
  const [canCreatePin, setCanCreatePin] = React.useState(false);

  const downloadContract = () => {
    getFetchBlob(`/my/reservations/${id}/contract`, `${id}.pdf`).then(res => {
    });
  };

  const createPinCode = async () => {
    if (!id) {
      return;
    }

    const result = await confirm('ロック解除番号を発行しますか？', {okText: 'は　い', cancelText: 'いいえ'});
    if (!result) {
      return;
    }

    let formData = new FormData();
    formData.append('reservation_id', id);

    await postFetch(`/my/reservations/${id}/lock_pins`, formData).then(res => {
      if (!!res?.error || !res?.reservation?.lock_pin) {
        NotificationManager.error('', 'ロック解除番号の発行失敗しました。しばらくおいてから再度お試してください。', 3000);
        return;
      }

      setLockPin(res.reservation.lock_pin);

      NotificationManager.success('', 'ロック解除番号を発行しました。', 3000);
    });
  }

  React.useEffect(() => {
    if (!id) {
      return;
    }

    getFetch(`/my/reservations/${id}`).then(res => {
      if (!!res?.error) {
        return;
      }

      setResponse(res);

      const startDateTime = Number(`${res.reservation.start_date}${res.reservation.start_time}`);
      const endDateTime = Number(`${res.reservation.end_date}${res.reservation.end_time}`);
      const now = Number(moment().format("YYYYMMDDHHmm"));

      if (now >= startDateTime && now <= endDateTime) {
        setCanCreatePin(true);
      }

      if (!!res.reservation.lock_pin) {
        setLockPin(res.reservation.lock_pin);
      }
    });
  }, []);

  return (
    <>
    <Header />
    <Container fluid="lg">
      <Breadcrumb className="mt-3">
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>ホーム</Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/my' }}>マイページ</Breadcrumb.Item>
        <Breadcrumb.Item active>予約詳細</Breadcrumb.Item>
      </Breadcrumb>

      <Table responsive className="text-nowrap">
        <tbody>
          <tr>
            <th className="w-25">会員番号</th>
            <td>{response?.my?.id}</td>
          </tr>
          <tr>
            <th>お名前</th>
            <td>{response?.my?.name1} {response?.my?.name2}</td>
          </tr>
        </tbody>
      </Table>

      {(CAN_USE_SLOCK === "true" && response?.reservation.shop_id === 2) &&
      <Card className="mt-3">
        <Card.Header>スマートロック</Card.Header>
        {lockPin ?
          <>
          <ListGroup.Item as="li">
              解除番号
              <span className="float-right">{lockPin.code}</span>
            </ListGroup.Item>
            <ListGroup.Item as="li" onClick={() => setShow(true)}>
              解除QRコード
              <span className="float-right">
                <QRCodeCanvas
                  value={lockPin.qr_code}
                  size={64}
                  bgColor={"#000000"}
                  fgColor={"#FFFFFF"}
                  level={"L"}
                  includeMargin={false}
                />
              </span>
            </ListGroup.Item>
            <ListGroup.Item as="li">
              有効時刻
              <span className="float-right">
                {moment.unix(lockPin.start_at - 32400).format('YYYY年MM月DD日 (ddd) HH時mm分')}
                &nbsp;〜&nbsp;
                {moment.unix(lockPin.end_at - 32400).local().format('YYYY年MM月DD日 (ddd) HH時mm分')}
              </span>
          </ListGroup.Item>

          <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
              <Modal.Title>解除QRコード</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <QRCodeCanvas
                value={lockPin.qr_code}
                size={256}
                bgColor={"#000000"}
                fgColor={"#FFFFFF"}
                level={"L"}
                includeMargin={false}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShow(false)}>
                閉じる
              </Button>
            </Modal.Footer>
          </Modal>

          </>
        :
          <>
          <Card.Footer>
            <Row>
              <Col>
                <Button variant="success" block onClick={createPinCode} disabled={!canCreatePin}>解除番号を発行する</Button>
                {!canCreatePin && <>* 解錠番号の発行は貸出期間中のみ可能です</>}
              </Col>
            </Row>
          </Card.Footer>
          </>
        }
      </Card>
      }

      {(response && response.reservation) &&
      <Card className="mt-3">
        <Card.Header>予約詳細</Card.Header>
        <ListGroup variant="flush">
          <ListGroup.Item as="li">
            予約番号
            <span className="float-right">#{response?.reservation.id}</span>
          </ListGroup.Item>
          <ListGroup.Item as="li">
            予約日時
            <span className="float-right">{moment.unix(response.reservation.created_at).format('YYYY年MM月DD日 (ddd) HH時mm分')}</span>
          </ListGroup.Item>
          <ListGroup.Item as="li">
            店舗
            <span className="float-right"><Link to={`/shops#${response.reservation.shop_id}`} className="text-danger">{GetEnumsName(Enums['shops']['id'], response.reservation.shop_id)}</Link></span>
          </ListGroup.Item>
          <ListGroup.Item as="li">
            車種
            <span className="float-right"><Link to={`/models/${response.reservation.model_id}`} className="text-danger">{response.reservation.model_name}</Link></span>
          </ListGroup.Item>
          <ListGroup.Item as="li">
            貸出希望日時
            <span className="float-right">{moment((String(response.reservation.start_date)+response.reservation.start_time), 'YYYYMMDDHHmm').format('YYYY年MM月DD日 (ddd) HH時mm分')}</span>
          </ListGroup.Item>
          <ListGroup.Item as="li">
            返却日時
            <span className="float-right">{moment((String(response.reservation.end_date)+response.reservation.end_time), 'YYYYMMDDHHmm').format('YYYY年MM月DD日 (ddd) HH時mm分')}</span>
          </ListGroup.Item>
          <ListGroup.Item as="li">
            車両補償
            <span className="float-right">{response.reservation.guarantee === 2 ? 'あり' : 'なし'}</span>
          </ListGroup.Item>
          <ListGroup.Item as="li">
            盗難補償
            <span className="float-right">{response.reservation.compensation === 2 ? 'あり' : 'なし'}</span>
          </ListGroup.Item>
          {(response.reservation.goods && response.reservation.goods.length > 0) &&
            <>
            {response.reservation.goods.filter(good => good.amount > 0).map(good => (
            <ListGroup.Item as="li">
              {good.name}
              <span className="float-right">{good.amount}個</span>
            </ListGroup.Item>
            ))}
            </>
          }
          <ListGroup.Item as="li">
            決済金額
            <span className="float-right"><NumericLabel>{response.reservation.amount}</NumericLabel>円</span>
          </ListGroup.Item>
          <ListGroup.Item as="li">
            予約状態
            <span className="float-right">{GetEnumsName(Enums['reservations']['status'], response.reservation.status)}</span>
          </ListGroup.Item>
        </ListGroup>
        <Card.Footer>
          <Row>
            <Col>
              <Button variant="secondary" block as={Link} to="/my">予約一覧へ</Button>
            </Col>
            <Col>
              <Button variant="info" block as="a" onClick={downloadContract}>貸渡契約書をダウンロード</Button>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
      }


      <LogoutButton className="mt-3 bg-red-700" />
    </Container>

    <Footer />
    </>
  );
};

export default Show;
